import React from "react";

import styled from "styled-components";

const Container = styled.div`
    width: 100px;
    height: 100px;
    background-color: red;
`;

function LandingPage(props) {
    return <Container />;
}

export default LandingPage;
